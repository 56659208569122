body{
  background: #F8F8F8;
}

a{
text-decoration: none;
}

h1{
text-align: center;
font-family: 'Lato', sans-serif;
font-size: 25px;
line-height: 32px;
padding-top: 70px;
a{
  color: #66CCCC;
}
}

.price-table-wrapper{
font-family: 'Lato', sans-serif;
text-align: center;
margin-top: 30px;

.featured-table{
  box-shadow: 0px 0px 19px -3px rgba(0,0,0,0.36);
}

.pricing-table{
  display: inline-block;
  border: 1px solid #C8C8C8;
  border-radius: 10px;
  background: white;
  margin: 20px;
  transition: all 0.3s ease-in-out;
  
  &__header{
    padding: 20px;
    font-size: 20px;
    color: #909090 ;
    background: #E0E0E0;
  }
  &__price{
    color: #66CCCC;
    padding: 20px;
    margin: auto;
    font-size: 40px;
    font-weight: 500;
  }
  &__button{
    display: block;
    background: #66CCCC;
    text-decoration: none;
    padding: 20px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    
    &:before{
      position: absolute;
      left: -20%;
      top: -10%;
      content: '';
      width: 60%;
      height: 220%;
      transform: rotate(-30deg);
      background: white;
      opacity: .3;
      transition: all 0.3s ease-in-out;
    }
    
     &:after{
        position: absolute;
        content: '>';
        top: 0;
        right: 0;
        font-size: 25px;
        padding: 15px;
        padding-right: 40px;
        color: white;
        opacity: 0;
       transition: all 0.3s ease-in-out;
      }
    
    &:hover{
      background: black;
    }
  }

  &__actual{
    display: block;
    background: #a0a0a0;
    text-decoration: none;
    padding: 20px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    
    
    
  }
  &__list{
    padding: 20px;
    color: #A0A0A0;
    li{
      padding: 15px;
      border-bottom: 1px solid #C8C8C8 ;
      
      &:last-child{
        border: none;
      }
    }
  }
  
  &:hover{
    box-shadow: 0px 0px 19px -3px rgba(0,0,0,0.36);
    
    .pricing-table__button{
      padding-left: 0;
      padding-right: 35px;
     &:before{
        top: -80%;
        transform: rotate(0deg);
        width: 100%;
      }
      &:after{
        opacity: 1;
        padding-right: 15px;
      }
    }
  }
}
}