.newReview{
    .container{
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        padding: 20px;
        margin-top: 5px;
        margin-left: 80px;
        margin-right: 80px;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 19px;
        hr {
            margin: 20px 0px;
            border: none;
            height: 1px;
            background-color: rgb(173, 166, 166);
          }
        .select{
            display: flex;
            //justify-content: space-between;
            //margin-left: 30px;
            //margin-right: 30px;
            gap: 10px;
            margin: auto;

            select{
                padding: 5px;
                border-radius: 5px;
                border: none;
                font-family: "Lato", sans-serif;
                font-size: 16px;
                font-weight: 700;
                font-style: normal; 
                margin-top: 15px;
                option{
                    font-family: "Lato", sans-serif;
                    font-size: 16px;
                    font-weight: 700;
                    font-style: normal;
                    border-radius: 5px;
                }
            }

        }
        .customer-review{
            display: flex;
            flex-direction: column;
            margin:auto;
            //gap: 20px;
            justify-content: center;

            .calificacion{
                text-align: center;
                font-family: "Lato", sans-serif;
                font-size: 14px;
                font-weight: 200;
                font-style: normal;           
                margin-bottom: 15px;
            }

            .input{
                //border: 1px solid #d6d4d4;
                font-family: "Lato", sans-serif;
                font-weight: 400;
                font-style: normal;
                //gap: 5px;
                justify-content: center;
                display: flex;
                //margin-left: 70px;
                align-items: center;
                margin-bottom: 7px;
                width: 550px;    

                .input_name{
                    width: 100%;
                }
                
                .input_value{
                    width: 50%;
                    text-align: center;
                 }
                input{
                    padding: 2px;
                    border-radius: 5px;
                    border: 1px solid #d6d4d4;
                    //width: 200%;
                    font-family: "Lato", sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;

                }
                .warning{
                    font-size: 13px;
                    width: 100%;  
                    margin-left: 20px;             
                 }
                 textarea{
                    border-radius: 5px;
                    border: 1px solid #d6d4d4;
                    resize: none;
                    font-family: "Lato", sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;

                 }


            }

            .bottom{
                display: flex;
                justify-content: right;
                align-items: center;
                gap: 20px;
                .err{
                    color: red;
                    font-size: 17px;

                }
                button{
                    margin-top: 5px;
                    padding: 7px;
                    font-family: "Lato", sans-serif;
                    font-size: 14px;
                    font-weight: 700;
                    font-style: normal;
                    border-radius: 5px;
                    background-color: rgb(8, 8, 250) ;
                    color:white
                }
            }
        }
        .job-review{
            display: flex;
            flex-direction: column;
            margin:auto;
            //gap: 20px;
            justify-content: center;

            .calificacion{
                text-align: center;
                font-family: "Lato", sans-serif;
                font-size: 14px;
                font-weight: 200;
                font-style: normal;           
                margin-bottom: 15px;
            }

            .input{
                //border: 1px solid #d6d4d4;
                font-family: "Lato", sans-serif;
                font-weight: 400;
                font-style: normal;
                //gap: 5px;
                justify-content: center;
                display: flex;
                //margin-left: 70px;
                align-items: center;
                margin-bottom: 7px;
                width: 550px;    

                .input_name{
                    width: 100%;
                }
                
                input{
                    padding: 2px;
                    border-radius: 5px;
                    border: 1px solid #d6d4d4;
                    //width: 200%;
                    font-family: "Lato", sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;

                }
                .warning{
                    font-size: 13px;
                    width: 100%;  
                    margin-left: 20px;             
                 }
                 textarea{
                    border-radius: 5px;
                    border: 1px solid #d6d4d4;
                    resize: none;
                    font-family: "Lato", sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;

                 }
                 .input_value{
                    width: 50%;
                    text-align: center;
                 }

            }

            .bottom{
                display: flex;
                justify-content: right;
                align-items: center;
                gap: 20px;
                .err{
                    color: red;
                    font-size: 17px;

                }
                button{
                    margin-top: 5px;
                    padding: 7px;
                    font-family: "Lato", sans-serif;
                    font-size: 14px;
                    font-weight: 700;
                    font-style: normal;
                    border-radius: 5px;
                    background-color: rgb(8, 8, 250) ;
                    color:white
                }
            }
        }
    }
    @media(max-width: 1100px)
    {
        .container{
            margin-left: 20px;
            margin-right: 20px;
        }
    }
}