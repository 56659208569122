.login {
  height: 100vh;
  background-color: #edf2ff;
  display: flex;
  align-items: center;
  justify-content: center;
  .card {
    width: 35%;
    min-width: 400px;
    display: flex;
    background-color: rgb(255, 255, 255);
    border: 1px solid #e3e2ff;
    border-radius: 10px;
    margin: 10px 10px;
    padding: 10px;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    .right {
      flex: auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        width: 58px;
        height: 50px;
        object-fit: cover;
        margin-bottom: 0px;
      }
      h2 {
        text-align: center;
        font-size: 25px;
        font-family: "Lato", sans-serif;
        font-weight: 700;
        font-style: normal;
      }
      form {
        max-width: 350px;
        margin-top: 10px;
        width: 75%;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
        gap: 30px;
        input {
          font-size: 16px;
          border: 1px solid #ccc;
          border-bottom: 1px solid lightgray;
          padding: 8px;
          border-radius: 5px;
          background-color: #f0f0f0;
        }
        span {
          font-size: 16px;
          color: red;
        }
        button {
          width: 100px, 33.33%;
          padding: 10px;
          border: none;
          background-color: #009bec;
          color: white;
          font-weight: bold;
          cursor: pointer;
        }
      }
      a {
        margin-left: 7px;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        text-align: center;
        color: #009bec;
        text-decoration: none;
        margin-top: 20px; // Añadir margen superior al enlace
      }
      p {
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        text-align: center;
        .a1 {
          margin-left: 10px;
          font-family: SFProDisplay-Regular, Helvetica, Arial, sans-serif;
          padding-left: 10px;
          font-size: 16px;
          color: #021f75;
        }
      }
      .login-container {
        position: relative;
      }
    }
  }
  @media (max-width: 768px) {
    .card {
      min-height: auto; // Opcional: elimina la altura para que el contenido determine la altura
      height: auto; // Opcional: elimina la altura para que el contenido determine la altura
      width: 90%; // Ocupa el 100% del ancho del contenedor .login
      max-width: 90%; // Asegúrate de que no haya un ancho máximo
      border-radius: 10px; // Opcional: elimina el borde redondeado para un mejor ajuste
      height: 100vh; // Opcional: ocupa toda la altura de la pantalla
      box-shadow: none; // Opcional: elimina la sombra para un diseño más plano
    }
  }
  @media(max-height: 768px) {
    .card {
      height: auto; // Opcional: elimina la altura para que el contenido determine la altura
    }
  }
}