.navbar {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  height: 50px;
  border-bottom: 1px solid lightgray;
  position: relative;
  top: 0;
  background-color: white;

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;

  a {
    text-decoration: none;
    color: black;
    font-size: 17px;
  }

  .menu-button {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 30px;

    span {
      font-weight: bold;
      font-size: 25px;
      
    }

    .logo {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 58px;
        height: 50px;
        object-fit: cover;
      }

      span {
        pointer-events: none;
      }
    }

    .search {
      display: flex;
      align-items: center;
      gap: 10px;
      border: 1px solid lightgray;
      border-radius: 5px;
      padding: 5px;

      input {
        border: none;
        width: 300px;
      }
    }

    .seccion_reseñas {
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-style: normal;

      .titulo_reseñas {
        border: none;
        background-color: white;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 17px;
        position: relative;
      }

      .menu_reseñas {
        width: 160px;
        margin-top: 5px;
        position: absolute;
        z-index: 1;
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 3px;

        .menu_item {
          background-color: white;
          padding: 5px;
          font-size: 15px;
          width: 100%;

          :hover {
            background-color: rgb(214, 203, 203);
            color: rgb(0, 0, 0);
            width: 100%;
          }
        }
      }
    }

    &.open {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      z-index: 1;

      a {
        padding: 10px 20px;
        text-decoration: none;
        color: #000;
        display: block;

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }

  .right {
    display: flex;
    gap: 15px;

    &.open {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50px;
      right: 20px;
      background-color: white;
      border: 1px solid lightgray;
      border-radius: 5px;
      padding: 10px;
      z-index: 1000;

      a {
        color: #333;
        text-decoration: none;
        padding: 10px;
        border-bottom: 1px solid lightgray;

        &:last-child {
          border-bottom: none;
        }
      }
    }
    .premium-badge {
      margin-left: 10px; /* Espacio entre el nombre y el badge */
      padding: 2px 6px;
      background-color: gold;
      color: white;
      border-radius: 4px;
      font-size: 12px;
    }


  }
}

@media (max-width: 1015px) {
  .navbar {
    .left {
      display: none;
    }

    .right {
      display: none;
    }

    .menu-button {
      display: block;

      .user-icon {
        width: 24px; // Ajusta el tamaño según sea necesario
        height: 24px; // Ajusta el tamaño según sea necesario
      }
    }

    .left a,
    .left .dropdown-toggle {
      padding: 10px;
      color: #000;
      text-decoration: none;
      display: block;
      width: 100%; /* Asegura que todos los botones tengan el mismo ancho */
      box-sizing: border-box; /* Incluye el padding y el border en el ancho total */
    }
    
    .left a:hover,
    .left .dropdown-toggle:hover {
      background-color: #f0f0f0; /* Color de sombreado */
      border-radius: 4px; /* Opcional: para redondear las esquinas */
    }
    
    /* Estilos específicos para el menú desplegable */
    .seccion_reseñas {
      position: relative;
      width: 100px; // Agrega un ancho definido
    }
    
    .seccion_reseñas .dropdown-menu {
      position: absolute;
      top: 0;
      right: 0; // Cambia a right: 0 para que se posicione a la derecha
      left: auto; // Agrega left: auto para que se posicione a la derecha
      width: 160px;
      background-color: #fff;
      border: 1px solid #ddd;
      padding: 10px;
      z-index: 1;
      transform: translateY(0);
    }
    
    .seccion_reseñas.open .dropdown-menu {
      display: block; /* Muestra el menú cuando la clase 'open' está presente */
    }
    
    .seccion_reseñas .dropdown-menu::before {
      content: "";
      position: absolute;
      top: 10px;
      left: -10px; /* Cambia para que la flecha apunte hacia la izquierda, justo en la separación del botón */
      transform: translateY(-50%);
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #fff;
    }

  .left.open {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 75%;
    gap: 20px;

    .dropdown-right {
      position: relative;
    }

    .dropdown-right .dropdown-menu {
      right: 0;
      left: auto;
    }

    .logo {
      align-self: flex-start;
    }

    &>* {
      margin: 1px 0;
      width: 100px;
      padding: 10px 2px;
      border-bottom: 1px solid lightgray;

      &:last-child {
        border-bottom: none;
      }
    }

    .menu-item {
      padding: 10px 20px;
      text-decoration: none;
      color: #000;
      display: block;

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }


  .right.open {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    gap: 20px;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;

    a {
      padding: 10px 20px;
      text-decoration: none;
      color: #000;
      display: block;

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
  }
}