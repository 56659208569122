.rightBar {
  @media (max-width: 1015px) {
    display: none;
  }

  z-index: 1;
  flex: 2;
  position: sticky;
  top: 70px;
  height: calc(100vh - 70px);
  overflow: scroll;
  background-color: whitesmoke;
  font-family: "Lato",
  sans-serif;
  font-weight: 400;
  font-style: normal;

  &::-webkit-scrollbar {
    display: none;
  }

  .container {
    padding: 20px;

    .item {
      background-color: white;
      justify-content: center;
      align-items: center;
      border: 1px solid lightgray;
      border-radius: 20px;
      padding: 20px;
      margin-top: 10px;
      overflow: hidden; /* Asegura que el contenido no se salga del contenedor */
      box-sizing: border-box; /* Incluye el padding y el border en el tamaño total del contenedor */

      .header {
        display: flex;
        flex-direction: column; /* Asegura que los elementos hijos se apilen verticalmente */
        gap: 10px;

        span {
          text-align: center;
          color: gray;
          font-size: 19px;
        }
      }

      .link-with-icon {
        display: flex;
        align-items: top;
        gap: 5px; /* Reducido el espacio entre el icono y el enlace */
        margin-top: 10px;
      }

      .links {
        display: flex;
        flex-direction: column; /* Asegura que los enlaces se apilen verticalmente */
        gap: 8px; /* Espacio entre los enlaces */
       // margin-left: 32px; /* Alinea el segundo enlace con el primero */
      }

      p {
        font-size: 18px;
        padding: 10px;
      }

      ul {
        list-style-type: none;
        font-size: 19px;
        padding: 10px;
      }
    }
  }
}