@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.principalPage {
  flex: 8;
  text-align: center;
  //margin-top: 25px;
  min-width: none;

  .banner1 {
    //border: 1px solid black;
    //padding: 20px;
    //margin: auto;
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    justify-content: center;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-style: normal;
    h1 {
      font-family: "Lato", sans-serif;
      font-weight: 700;
      font-style: normal;
      color: #274ec5;
      font-size: 50px;
      margin-top: 0px;
    }
    p{
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 22px;
      width: 80%;
      margin: auto;
    }
    img {
      width: 15%;
      height: 15%;
      margin: auto;
      margin-bottom: 10px;
      margin-top: 18px;
    }
    a{
      text-decoration: none;
      color: rgb(23, 56, 204);
      font-size: 20px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
  .banner2 {
    padding: 20px;
    margin-left: 20px;
    margin-bottom: 5px;
    background-color: white;
    h3 {
      font-size: 25px;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      font-style: normal;
    }
    p {
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 20px;
    }
    .imgs {
      display: flex;
      justify-content: center;
      img {
        width: 10%;
        height: 10%;
        margin: 10px;
      }
    }
  }

  .banner3 {
    padding: 20px;
    //margin-left: 20px;
    //margin-bottom: 5px;
    background-color: whitesmoke;
    h3 {
      font-size: 25px;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      font-style: normal;
    }
    p {
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 20px;
    }
    img {
      width: 10%;
      height: 10%;
      margin: 10px;
    }
  }
  @media (max-width: 1250px) and (min-width: 1151px) {
    max-width: 650px;
  }
  @media (max-width: 1150px) and (min-width: 1016px) {
    max-width: 550px;
    
  }
  @media (max-width: 1015px) and (min-width: 768px) {
  }
  @media (max-width: 768px)
  {
    .principalPage{
      min-width: none;
    }
  }
}
