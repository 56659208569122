.about {
  .container {
    padding: 20px;
    background-color: whitesmoke;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;

    .item {
      background-color: white;
      display: flex;
      flex-direction: column;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 15px;
      justify-content: center;
      align-items: center;
      border: 1px solid lightgray;
      border-radius: 20px;
      padding: 20px;
      width: 70%;
      span {
        font-size: 25px;
        font-weight: bold;
      }
      p {
        font-size: 22px;
        padding: 10px;
      }
    }

    h3 {
      margin-top: 50px;
      text-align: center;
      font-size: 25px;
    }

    .team {
      margin-top: 20px;
      width: 100%;
      height: auto;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      background-color: rgb(236, 234, 234);

      .member {
        margin-top: 20px;
        margin: 10px;
        height: 250px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        align-items: center;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        background-color: white;

        .foto_member {
          margin-top: 5px;
          margin-bottom: 10px;
          pointer-events: none;
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }

        span {
          font-size: 23px;
          font-weight: bold;
        }

        p {
          font-size: 21px;
        }
        a {
          margin: 0 10px; /* Añade un margen horizontal para separar los logos */
        }
        .logo_linkedin, .logo_github {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  @media (max-width: 960px) {
    .team {
      flex-wrap: wrap;
      flex-direction:row; /* Permite que los elementos se envuelvan */
      justify-content: space-around; /* Distribuye el espacio entre los elementos */
      height: auto; /* Ajusta la altura para que se adapte al contenido */
      margin-bottom: 50px; /* Añade un margen inferior para evitar superposición con el footer */
      width: 100%;

      .member {
        max-width: 280px;
        flex-basis: 40%; /* Cada miembro ocupará aproximadamente el 45% del ancho del contenedor */
        margin-bottom: 20px; /* Añade un margen inferior para separar los miembros */
      }
    }
  }
}