.torta-chart-box{
    display: flex;
    width: 100%;
    height: 100%;
    .boxInfo{
        background: rgb(240, 110, 212);
        flex: 1;
        border-radius: 5px;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 15px;
        .title{
            text-align: center;
            font-size: 15px;
        }
        .desc{
            text-align: center;
            font-size: 15px;
        }
    }

    .chartInfo{
        flex:3;
        //background: rgb(209, 65, 65);
        align-items: center;
        .chart{

            //background-color: aqua;
            height: 80%;
            width: 100%;
        }
        .convenciones{
            //background-color: rgb(55, 0, 255);
            display: flex;
            gap: 50px;
            justify-content: center; 
            font-size: 14px;   


            //flex-direction: column;
            .option{
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;

                .title{
                    display: flex;
                    gap: 5px;
                    align-items: center;

                    .dot{
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                    }
                }

            }
        }
    }

}