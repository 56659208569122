.my-reviews{
    h2{

    font-family: "Lato", sans-serif;
    font-weight: 200;
    font-style: normal;
    text-align: center;
    }
    .container{
        margin-bottom: 20px;
    }
}