.value_proposal {
    .container {
      padding: 20px;
      //margin-top: 50px;
      background-color: whitesmoke;
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 20px;
  
      .item {
        background-color: white;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 15px;
        justify-content: center;
        align-items: center;
        border: 1px solid lightgray;
        border-radius: 20px;
        padding: 20px;
        width: 70%;
        span {
          font-size: 25px;
          font-weight: bold;
          //text-align: center;
        }
        p {
          font-size: 22px;
          padding: 10px;
        }
      }
  
      h3 {
        margin-top: 50px;
        text-align: center;
        font-size: 25px;
      }
  
      .team {
        margin-top: 20px;
        //padding: 20px;
        width: 100%;
        height: 380px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        background-color: rgb(236, 234, 234);
  
        .member {
          margin-top: 20px;
          margin: 10px;
          height: 230px;
          padding: 20px;
          border: 1px solid #ccc;
          border-radius: 5px;
          align-items: center;
          text-align: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          background-color: white;
  
          .foto_member {
            margin-top: 5px;
            margin-bottom: 10px;
            pointer-events: none;
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }
  
          span {
            font-size: 24px;
            font-weight: bold;
          }
  
          p {
            font-size: 21px;
          }
          .logo_linkedin {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
  