.job-reviews{
    .header{
        font-family: "Lato", sans-serif;
        font-weight: 200;
        font-style: normal;
        text-align: center;
        }
        .search_input{
            width: 90%;
            //border: 1px solid #ccc;
            display: flex;
            justify-content: center;
            gap: 10px;
            //margin-bottom: 15px;
            margin: auto;
                input{
                    padding: 10px;
                    border-radius: 5px;
                    width: 60%;
        
                }
                button{
                    //padding: 10px;
                    height: 40px;
                    border-radius: 5px;
                    background-color: #f1f1f1;
                    border: none;
                    cursor: pointer;
                }
            }
        .filter{
            width: 60%;
            //border: 1px solid #ccc;
            display: flex;
            justify-content: center;
            gap: 10px;
            //margin-bottom: 15px;
            margin: auto;
            align-items: center;
            .filter-info{
                
            }
            .options{
                width: 30%;
                margin-top: 15px;
                select{
                    border-radius: 15px;
                    option{
                        border-radius: 5px;
                    }
                }
            }
        }
            .no-reviews{
                font-family: "Lato", sans-serif;
                font-weight: 100;
                font-style: normal;
                text-align: center;
            }
}