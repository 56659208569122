.tyc {
  .container {
    padding: 10px;
    //margin-top: 50px;
    background-color: whitesmoke;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;

    .item {
      background-color: white;
      display: flex;
      flex-direction: column;
      margin: auto;
      margin-top: 10px;
      margin-bottom: 7px;
      justify-content: left;
      align-items: left;
      border: 1px solid lightgray;
      border-radius: 20px;
      padding: 5px;
      width: 70%;

      span {
        margin-top: 20px;
        font-size: 25px;
        font-weight: bold;
        text-align: left;
        margin-bottom: 10px; // Aumenta este valor para más espacio después de cada <span>
      }
      
      p {
        font-size: 22px;
        padding: 1px;
        margin-bottom: 5px; // Reduce este valor para menos espacio después de cada <p>
      }
    }

    h3 {
      margin-top: 10px;
      text-align: center;
      font-size: 25px;
    }

  }

  .tittle {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
  }
}
