.recovery {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;

    .card {
        width: 50%;
        display: flex;
        border-radius: 10px;
        max-height: 600px;
        box-shadow: 0 0 10px rgba(0,0,0,0.15);
        background-color: white;

        .left {
            flex: 1;
            padding: 20px;
            border-right: 1px solid #ddd; // División entre el lado derecho y el izquierdo
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .header {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
            }

            .header-img {
                width: 75px; 
                height: auto; 
                margin-right: 10px;
            }

            .header-text {
                font-size: 24px;
                font-weight: bold;
            }

            form {
                display: flex;
                flex-direction: column;

                input {
                    margin-bottom: 10px;
                    padding: 10px;
                    font-size: 16px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                }

                button {
                    padding: 10px;
                    font-size: 16px;
                    border: none;
                    border-radius: 4px;
                    background-color: #009bec;
                    color: white;
                    cursor: pointer;
                }
            }
        }

        .right {
            flex: 1;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                margin-bottom: 20px;
                font-size: 16px;
            }

            .login_recovery, .register_recovery {
                display: block;
                width: 100%;
                padding: 10px;
                font-size: 16px;
                border: none;
                border-radius: 4px;
                background-color: #009bec;
                color: white;
                cursor: pointer;
                text-align: center;
                text-decoration: none;
                margin-bottom: 10px;
            }
        }
    }

    @media (max-width: 768px) {
        .card {
            width: 100%;
            display: flex;
            flex-direction: column;

            .left {
                border-right: none;
            }

            .right {
                order: 1; // Asegura que .right aparezca después de .left
            }
        }
    }
}