@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
body {
  margin: 0;
  font-family: 'Arial', sans-serif; // Cambia 'Arial' al nombre de la fuente que quieras usar
}
.account-confirmation {
  //height: 100vh;
  display: flex;
  align-items: center;
  background-color: rgb(240, 243, 255);
  justify-content: center;
  

  .card {
    width: 35%;
    display: flex;
    background-color: rgb(255, 255, 255);
    border: 1px solid #e3e2ff;
    border-radius: 10px;
    //min-height: 600px;
    //height: 100vh;
    margin: 10px 10px;
    padding: 10px;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
    
    .terms-text {
      font-family: 'Arial', sans-serif; // Cambia 'Arial' al nombre de la fuente que quieras usar
      font-size: 14px; // Cambia '14px' al tamaño de fuente que quieras usar
    }


    .header {
      flex: 1;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      h1 {
        text-align: center;
        font-size: 30px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 700;
        font-style: normal;
      }
    }

    form {
      //margin-top: 10px;
      max-width: 350px;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;
      gap: 20px;
      p {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-style: bold;
        font-size: 18px;
        margin: auto;
        text-align: center;
      }

      input {
        font-size: 16px;
        border: 1px solid #ccc;
        border-bottom: 1px solid lightgray;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: #f0f0f0;
      }
      input:invalid[focused="true"] ~ span {
        display: block;
      }
      span {
        font-size: 16px;
        color: red;
        display: none;
      }
      .p2 {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        margin: auto;
        text-align: center;
      }
      .error_general {
        font-size: 16px;
        color: red;
      }
      button {
        width: 100px, 33.33%;
        padding: 10px;
        border: none;
        background-color: #009bec;
        color: white;
        font-weight: bold;
        cursor: pointer;
      }
      .terms-text {
        font-family: 'Arial', sans-serif;
        font-size: 14px; 
        text-align: center;
      }
      a {
        text-align: center;
        color: #009bec;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        text-decoration: none;
      }
    }
    a {
      text-align: center;
      color: #009bec;
      font-size: 18px;
    }    
  }
}
