.footer {
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 50px;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-top: 1px solid gray;
  background-color: white;
  a {
    font-size: 20px;
    color: #000;
    text-decoration: none;
    margin-right: 15px;
  }
  .container {
    padding: 10px;
    margin-right: 15px;
    span {
      font-size: 20px;
      color: #000;
    }
  }
  .social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    span {
      display: block;
      text-align: center;
      pointer-events: none;
      align-items: center;
      padding: 10px;
    }
    a {
      margin-right: 20px;
    }
  }
  @media (max-width: 768px) {
    .container {
      span {
        font-size: 15px;
      }
    }
    a {
      font-size: 15px;
    }
    .social-media {
      span {
        font-size: 15px;
      }
    }
  }
}
