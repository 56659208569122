
.bar-chart-box{

    display: flex;
    width: 100%;
    height: 100%;
    .boxInfo{
        background: rgb(240, 220, 110);
        flex: 1;
        border-radius: 5px;
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 15px;
        .title{
            text-align: center;
            font-size: 15px;
        }
        .desc{
            text-align: center;
            font-size: 15px;
        }
    }

    .chartInfo{
        flex:3;
        background: white;
        .chart{
            height: 100%;
            width: 100%;
        }
    }
}