.go-back {
    display: flex;
    align-items: center;
    text-decoration: none;
    size: 20px;
    color: black; // Cambia el color según tu preferencia
  }
  
  .arrow {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid black;
    margin-right: 5px;
  }
  
  .text {
    font-size: 16px; // Tamaño del texto
  }
  