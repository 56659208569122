.customer-reviews-page{
    .header{
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    }
    .search_input{
        width: 80%;
        margin: auto;
        //border: 1px solid #ccc;
        display: flex;
        gap: 10px;
        margin-bottom: 15px;
        justify-content: center;
        align-items: center;
        gap: 15px;
            .input{
               // padding: 10px;
                border-radius: 5px;
                width: 80%;
                margin-top: 10px;
                border-radius: 10px;
            }
            .button{
                padding: 5px;
                border-radius: 5px;
                background-color: #f1f1f1;
                border: none;
                cursor: pointer;
                margin-bottom: 10px;
        
            }
        }

    .no-reviews{
        font-family: "Lato", sans-serif;
        font-weight: 100;
        font-style: normal;
        text-align: center;
    }
}