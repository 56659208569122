.dashboard{
    padding: 10px;
    display: grid;
    gap:10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: minmax(180px, auto);
    .box{
        border-radius: 10px;
        border: 1px solid #c0bfbf;
        padding: 5px;
    }
    .box1{
        grid-column: span 2;
        grid-row: span 1;

    }
    .box2{
        grid-column: span 2;
        grid-row: span 1;
    }
    .box3{
        grid-column: span 4;
        grid-row: span 2;
    }
}