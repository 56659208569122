@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

body {
  margin: 0;
}

.register {
  display: flex;
  align-items: center;
  background-color: rgb(240, 243, 255);
  justify-content: center;

  .card {
    width: 35%;
    min-width: 400px;
    display: flex;
    background-color: rgb(255, 255, 255);
    border: 1px solid #e3e2ff;
    border-radius: 10px;
    margin: 10px 10px;
    padding: 10px;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

    .terms-text {
      font-family: 'Arial', sans-serif;
      font-size: 14px;
      text-align: center;
      word-wrap: break-word; // Permite que el texto se ajuste a la pantalla
      width: 100%; // Asegura que el texto ocupe todo el ancho disponible
    }

    .header {
      //border: 1px solid black;
      flex: 1;
      padding: 20px;
      display: flex;
      flex-direction: column;
      //gap: 20px;
      justify-content: center;
      text-align: center;
      h2 {
       
        font-size: 30px;
        font-family: "Lato", sans-serif;
        font-weight: 700;
        font-style: normal;
        margin: auto;
      }
      .img{
        //border-radius: 80%;
        margin: auto;
        //border: 1px solid black;
        img{
          
        width: 60px;
        height: 60px;
        object-fit: cover;
        }
      }
    }

    form {
      max-width: 350px;
      display: flex;
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;
      gap: 20px;

      // Estilo común para todos los elementos de texto en el formulario
      * {
        font-family: "Lato", sans-serif;
        font-weight: 400;
        font-style: normal;
      }

      p {
        font-size: 18px;
        margin: auto;
        text-align: center;
      }

      input {
        color: black;
        font-size: 16px;
        border: 1px solid #ccc;
        border-bottom: 1px solid lightgray;
        padding: 8px;
        border-radius: 5px;
        background-color: #f0f0f0;
      }

      input:invalid[focused="true"] ~ span {
        display: block;
      }

      span {
        font-size: 16px;
        color: red;
        display: none;
      }

      .p2 {
        font-size: 12px;
        margin: auto;
        text-align: center;
      }

      .error_general {
        font-size: 16px;
        color: red;
      }

      button {
        width: 100px, 33.33%;
        padding: 10px;
        border: none;
        background-color: #009bec;
        color: white;
        font-weight: bold;
        cursor: pointer;
      }

      .terms-text {
        font-size: 14px;
        text-align: center;
      }

      a {
        text-align: center;
        color: #009bec;
        font-size: 14px;
        text-decoration: none;
      }
    }

    a {
      text-align: center;
      color: #009bec;
      font-size: 18px;
    }
.form-group {
  display: flex;
  align-items: center; // Centra verticalmente el contenido
  margin-bottom: 0px;
  width: 350px;

  input[type="checkbox"] {
    margin-left: 2px;
    width: 20px;
  }

  label {
    min-width: none;
    max-width: 350px;
    padding-left: 10px;
    font-size: 14px;
    text-align: left;
    margin-left: 0px;
    white-space: normal;
    word-wrap:normal;
  }
}
  }

  @media (max-width: 768px) {
    .card {
      width: 90%;
      max-width: 900%;
      height: 100vh;
      box-shadow: none;
    }
  }
  @media(max-height: 768px) {
    .card {
      height: auto; // Opcional: elimina la altura para que el contenido determine la altura
    }
  }
}